import React from 'react'
import { Helmet } from 'react-helmet';

export default function Etusivu() {

    const txt1 = [];
    const txt2 = [];
    const txt3 = [];
    const txt4 = [];
    if ('en' in localStorage) {
        txt1.length = 0;
        txt2.length = 0;
        txt3.length = 0;
        txt4.length = 0;

        txt1.push({id: 0, value: 'Patean Kone was founded in 1967 and is located in Piippola Finland. We are a metal fabrication and machining shop that'});
        txt1.push({id: 1, value: ' specializes in making additional light brackets as well as offering a painting service for metal components.'});
        txt1.push({id: 2, value: 'We have specialist equipment such as an inertia dynamometer.'});
        txt1.push({id: 3, value: 'More information'});

        txt2.push({id: 0, value: 'History'});
        txt2.push({id: 1, value: 'Patean Kone body shop was founded in 1967 by Paavo Anttila, originally offering painting and bodywork services. Operations started in Pulkkila with the name Anttilan Korjaamo before being changed to Patean Kone in the early 80s. The shop in Pulkkila was an old vicarage barn which Pulkkila’s first ever ambulance was manufactured in by Anttilan Korjaamo. Before moving to Piippola the manufacturing of car trailers and small trailers had begun.'});
        txt2.push({id: 2, value: 'In 1976 the body shop moved to its current location, Piippola. In Piippola the manufacturing of trailers carried on with the additional production of three portable circular sawmills and forest trailers. Car painting and bodywork continued in the new location until 2011, at this time we transitioned into fabrication and machining.'});

        txt3.push({id: 0, value: 'Additional light brackets'});
        txt3.push({id: 1, value: 'We have manufactured different light brackets for almost 20 years. Our brackets are made out of 2mm stainless steel or 3mm mild steel versions which are painted.'});
        txt3.push({id: 2, value: 'We have models for all kinds of different lights such as LED bars or round lights, it is also possible to order custom brackets.'});
        txt3.push({id: 3, value: 'More info through phone or mail'});

        txt4.push({id: 0, value: 'Dynamometer'});
        txt4.push({id: 1, value: 'Our Dynamometer uses an inertia-based roller which produces very accurate horsepower and torque values for two-wheel drive cars. Tuning race cars is another area of expertise for us in which we have vast experience in. We can tune carburetor engines as well as some of the most popular ECUs such as: MoTeC, MaxxECU, Tatech, etc.'});
        txt4.push({id: 2, value: 'For more information and appointments:'});
    } else {
        txt1.length = 0;
        txt2.length = 0;
        txt3.length = 0;
        txt4.length = 0;

        txt1.push({id: 0, value: 'Patean Kone on vuonna 1967 perustettu yritys, jonka päätoimialana on erilaiset metallityöt ja manuaaliset koneistukset.'});
        txt1.push({id: 1, value: 'Olemme erikoistuneet erilaisten lisävalotelineiden valmistamiseen. Maalaamme myös erinlaisia metalliosia. Suoritamme myös autojen tehonmittauksia ja säätämistä.'});
        txt1.push({id: 2, value: 'Sijaitsemme Piippolassa iisalmentien varrella.'});
        txt1.push({id: 3, value: 'Lisätietoa tehonmittauksesta'});

        txt2.push({id: 0, value: 'Historia'});
        txt2.push({id: 1, value: 'Patean Koneen autokorjaamon perusti Paavo Anttila vuonna 1967 Pulkkilassa. Toiminta alkoi Anttilan Korjaamo nimellä ja myöhemmin 80-luvun alussa nimi muutettiin Patean Koneeseen. Toimipaikkana Pulkkilassa toimi vanha pappilan navetta, jossa oli jo aikaisemmin pidetty metallikurssia. Pulkkilan ensimmäinen ambulanssi tehtiin Anttilan korjaamon toimesta, ja jo ennen Piippolaan siirtymistä oli ehditty valmistaa monia pieniä peräkärryjä ja muutama autonkuljetustraileri.'});
        txt2.push({id: 2, value: 'Vuonna 1976 toimipaikka vaihtui Piippolaan, jossa toiminta on vieläkin. Piippolassa trailereiden ja pienempien kärryjen valmistus jatkui; valmistettiin 3kpl kenttäsirkkeleitä ja muutama metsäkärry.  Autojen maalaukset aloitettiin heti toiminnan alkaessa Pulkkkilassa, ja maalaustöitä tehtiin 2000-luvulle saakka. Vuonna 2011 täysipäiväinen autojen korjaus ja maalaus lopetettiin ja siirryttiin metallitöihin.'});

        txt3.push({id: 0, value: 'Lisävalotelineet'});
        txt3.push({id: 1, value: 'Olemme valmistaneet erilaisia lisävalotelineitä jo lähes 20 vuotta. Teemme ruostumattomasta teräksestä valmistettuja lisävalotelineitä sekä 3mm paksuisesta raudasta maalattuja versiota.'});
        txt3.push({id: 2, value: 'Valikoimasta löytyvät telineet niin pyöreille valoille kuin ledipaneleille. Voimme myös tehdä asiakkaan vaatimuksien mukaan heille sopivan mallin.'});
        txt3.push({id: 3, value: 'Lisätietoa soittamalla tai sähköpostitse'});

        txt4.push({id: 0, value: 'Tehonmittaus'});
        txt4.push({id: 1, value: 'Tehonmittauksessa on käytössä inertiaan perustuva dynamometrirulla, joka antaa tarkat mittaustulokset. Tehot voidaan mitata kaksivetoisista autoista. Säätötyöt onnistuvat kaasutinmoottoreihin ja yleisimpiin jälkiasennettuihin boxeihin. Kilpa-autojen säätämisestä löytyy runsaasti kokemusta. Ohessa muutamia esimerkkiohjelmia, joita olemme säätäneet: MoTeC, MaxxECU, Tatech jne.'});
        txt4.push({id: 2, value: 'Lisätietoa ja ajanvaraus soittamalla tai sähköpostilla'});
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Patean Kone</title>
                <meta name="description" content="Patean Koneen päätoimialaa ovat erilaiset metallityöt ja manuaaliset koneistukset. Olemme erikoistuneet erilaisten lisävalotelineiden valmistamiseen. Maalaamme myös erinlaisia metalliosia. Suoritamme myös autojen tehonmittauksia ja säätämistä."/>
                <meta name="keywords" content="Patean Kone Oy, patean kone oy, Patean Kone, patean kone, pateankone, koneistus, koneistukset, lisävaloteline, lisävalotelineet, lisävalotelineet autoon, tehonmittaus, tehonmittaukset, auton tehonmittaus"/>
                <link rel="canonical" href="https://pateankone.com" />
                <meta name="robots" content="index, archive"/>
            </Helmet>
            <div id="carouselExampleFade" className="carousel slide carousel-fade nBorder d-none d-lg-block" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active data-bs-interval='10000'">
                    <img src={require("../Kuvat/img130.jpg").default} className="cImg" alt="metallityot1"/>
                    <div className="carousel-caption">
                    <p>{txt1[0].value}<br/>{txt1[1].value}
                        <br/>
                        <br/>
                        {txt1[2].value}
                        <br/>
                        <br/>
                        <a href="#tehonmittaus"><span className='tehonmittaus2'>{txt1[3].value}</span>
                        <i className="fas fa-angle-down"></i></a> 
                    </p>
                    </div>
                    </div>
                    <div className="carousel-item data-bs-interval='10000'">
                    <img src={require("../Kuvat/IMG_3206.JPG").default} className="cImg" alt="metallityot2"/>
                    <div className="carousel-caption">
                    <p>{txt1[0].value}<br/>{txt1[1].value}
                        <br/>
                        <br/>
                        {txt1[2].value}
                        <br/>
                        <br/>
                        <a href="#tehonmittaus"><span className='tehonmittaus2'>{txt1[3].value}</span>
                        <i className="fas fa-angle-down"></i></a> 
                    </p>
                    </div>
                    </div>
                    <div className="carousel-item data-bs-interval='10000'">
                    <img src={require("../Kuvat/img114.jpeg").default} className="cImg" alt="tehonmittaus"/>
                    <div className="carousel-caption">
                    <p>{txt1[0].value}<br/>{txt1[1].value}
                        <br/><br/>
                        {txt1[2].value}
                        <br/><br/>
                        <a href="#tehonmittaus"><span className='tehonmittaus2'>{txt1[3].value}</span>
                        <i className="fas fa-angle-down"></i></a> 
                    </p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="nBorder d-lg-none mobileImg">
                <p className='cVarjo2'>{txt1[0].value}<br/>{txt1[1].value}
                        <br/>
                        <br/>
                        {txt1[2].value}
                        <br/>
                        <br/>
                        <a href="#tehonmittaus"><span className='tehonmittaus2'>{txt1[3].value}</span>
                        <i className="fas fa-angle-down"></i></a> 
                    </p>
            </div>
            <div className="row nBorder">
                <div className="content-1bg borderR">
                    <div className="bg-1 content-1">
                            <h1>{txt2[0].value}</h1>
                            <p>{txt2[1].value}
                                <br/><br/>
                                {txt2[2].value}
                            </p>
                    </div>
                    
                </div>
                
                <div className='lvkuva1'></div>
            </div>

            <section id='lisavalotelineet' className="row nBorder">
            <div className='lvkuva'></div>
                <div className="content-3 bg-1">
                    <h1>{txt3[0].value}</h1>
                    <p>
                        {txt3[1].value}
                        
                        <br/><br/>
                        {txt3[2].value}
                    </p>
                    <img src={require("../Kuvat/IMG_3133.JPG").default} alt='lisavaloteline' className='lvt border d-lg-none'></img>
                    <p>{txt3[3].value}</p>
                    <p>Kimmo Anttila</p>
                    <p><i className="fas fa-phone"></i> +358 40 557 8629</p>
                    <p><i className="fas fa-envelope"></i> kimmo.anttila@pateankone.com</p>
                </div>
            </section>

            <section id="tehonmittaus" className="row cKuva nBorder">
            <div className="d-none d-md-block col-md-1 col-lg-2 cVarjo"></div>
                <div className=" col-12 col-md-10 col-lg-8 content-2 cVarjo">
                    <h1>{txt4[0].value}</h1>
                    <p>{txt4[1].value}</p>

                    <p>{txt4[2].value}</p>
                    <p>Kimmo Anttila</p>
                    <p><i className="fas fa-phone"></i> +358 40 557 8629</p>
                    <p><i className="fas fa-envelope"></i> kimmo.anttila@pateankone.com</p>
                </div>
                <div className="d-none d-md-block col-md-1 col-lg-2 cVarjo"></div>
            </section>
        </div>
    )
}
