import {React} from 'react';

export default function Header({setLanguageStorage, clearLanguageStorage}) {
    function language() {
        if ('en' in localStorage) {
            clearLanguageStorage();
            
        } else {
            setLanguageStorage();
        }
    }
    const links = [];
    if ('en' in localStorage) {
        links.length = 0;
        links.push({id: 0, value: 'Home'});
        links.push({id: 1, value: 'Dynamometer'});
        links.push({id: 2, value: 'Additional light brackets'});
    } else {
        links.length = 0;
        links.push({id: 0, value: 'Etusivu'});
        links.push({id: 1, value: 'Tehonmittaus'});
        links.push({id: 2, value: 'Lisävalotelineet'});
    }
    return (
        <div className="bg-1 row m-0 p-0 pb-5 nBorder">
            <div className='cc d-none d-lg-block'><span className='cc2'></span>© Joni Anttila</div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">Patean Kone</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                <a className={window.location.pathname === '/patea-racing-team' ? 'nav-link': 'nav-link active'} href="/">{links[0].value}</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="/#lisavalotelineet">{links[2].value}</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="/#tehonmittaus">{links[1].value}</a>
                                </li>
                                <li className="nav-item languages">
                                <span role={'button'} onClick={language} className="nav-link"><span className={'en' in localStorage ? null: 'language'}>fi</span> | <span className={'en' in localStorage ? 'language': null}>en</span> </span>
                                </li>
                            </ul>
                    
                    <a href="patea-racing-team" className="prt">

                    <img className="logo img-fluid d-none d-lg-inline" src={require("../Kuvat/PRT.png").default} alt="PRTlogo"></img>
                        Patea Racing Team
                    </a>
                    </div>
                </div>
            </nav>
        </div>
    )
}
