import React from 'react'
import { Helmet } from 'react-helmet';

export default function PateaRacing() {

    const pateaR = [];
    if ('en' in localStorage) {
        pateaR.length = 0;
        pateaR.push({id: 0, value: 'Coming soon'});
        pateaR.push({id: 1, value: 'Watch our Youtube channel'});
    } else {
        pateaR.length = 0;
        pateaR.push({id: 0, value: 'Tulossa pian'});
        pateaR.push({id: 1, value: 'Katso meidän Youtube-kanava'});
    }
    return (
        
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Patea Racing Team - Patean Kone</title>
                <meta name="description" content="Sivu tulossa pian. Katso Patea Racing Teamin Youtube kanava."/>
                <meta name="keywords" content="Patean Kone Oy, patean kone oy, Patean Kone, patean kone, pateankone, koneistus, koneistukset, lisävaloteline, lisävalotelineet, lisävalotelineet autoon, tehonmittaus, tehonmittaukset, auton tehonmittaus"/>
                <meta name="robots" content="index, archive"/>
            </Helmet>
            <section className="row cKuva2 nBorder placeholder">
            <div className="col-0 col-md-1 col-lg-2 cVarjo"></div>
                <div className="col-12 col-md-10 col-lg-8  content-2 cVarjo">
                    <h1  className="pb-3">{pateaR[0].value}</h1>
                    <a href='https://www.youtube.com/channel/UCX4SEjJooLDhgZSLFxD6BVA' target="_blank" rel="noreferrer">
                    <i className="fas fa-angle-double-right d-inline-block pe-1"></i>
                        <h5 className="d-inline">{pateaR[1].value}</h5>
                    </a>
                </div>
                <div className="col-0 col-md-1 col-lg-2 cVarjo"></div>
            </section>
        </div>
    )
}
