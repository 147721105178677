import { Routes, Route } from 'react-router-dom';
import {React, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import Header from './Komponentit/Header';
import Etusivu from './Sivut/Etusivu';
import Footer from './Komponentit/Footer';
import PateaRacing from './Sivut/PateaRacing';


function App() {
  const [lang, setLang] = useState(null);

  function setLanguageStorage() {
    const newLang = 'en';
    setLang(newLang);
    localStorage.setItem('en', JSON.stringify(newLang));
  }
  function clearLanguageStorage() {
    localStorage.clear('en');
    setLang(null);
  }

  useEffect(() => {
    if ('en' in localStorage) {
      setLang(JSON.parse(localStorage.getItem('en')))
    }
  }, [])

  return (
    <div className="container-fluid">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patean kone</title>
        <meta name="description" content="Patean Koneen päätoimialaa ovat erilaiset metallityöt ja manuaaliset koneistukset. Olemme erikoistuneet erilaisten lisävalotelineiden valmistamiseen. Maalaamme myös erinlaisia metalliosia. Suoritamme myös autojen tehonmittauksia ja säätämistä." />
        <meta name="keywords" content="Patean Kone Oy, patean kone oy, Patean Kone, patean kone, pateankone, koneistus, koneistukset, lisävaloteline, lisävalotelineet, lisävalotelineet autoon, tehonmittaus, tehonmittaukset, auton tehonmittaus"/>
      </Helmet>
      <Header setLanguageStorage={setLanguageStorage} clearLanguageStorage={clearLanguageStorage} lang={lang}/>
      <Routes>
        <Route exact path="/" element={<Etusivu />} />
        <Route exact path="/patea-racing-team" element={<PateaRacing />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
