import React from 'react'

export default function Footer() {
    const tiedot = [];
    if ('en' in localStorage) {
        tiedot.length = 0;
        tiedot.push({id: 0, value: 'CEO: '});
        tiedot.push({id: 1, value: 'Sales manager: '});
    } else {
        tiedot.length = 0;
        tiedot.push({id: 0, value: 'Toimitusjohtaja: '});
        tiedot.push({id: 1, value: 'Myyntipäällikkö: '});
    }
    return (
        <div className="row nBorder">
            <div className="footer2 bg-3">
                <div className="bg-2 footer">
                    <h3 className="pb-4">Patean Kone Oy</h3>
                    <p className="pb-2 vali"><a href="https://www.google.com/maps/place/M%C3%A4nnik%C3%B6ntie+1,+92620+Siikalatva/@64.1824922,26.0247591,13.25z/data=!4m5!3m4!1s0x46817c0d7dfa7e2d:0x8f7d403dbd321b4e!8m2!3d64.1777015!4d26.0369433" target="_blank" rel="noreferrer"><i className="fas fa-map-marker-alt"></i> Männiköntie 1, Piippola 92620</a></p>

                    <p className="pt-1">{tiedot[0].value}Iikka Anttila</p>
                    <p className="pb-3"><i className="fas fa-envelope"></i> iikka.anttila@pateankone.com</p>

                    <p>{tiedot[1].value}Kimmo Anttila</p>
                    <p><i className="fas fa-envelope"></i> kimmo.anttila@pateankone.com</p>
                    <p><i className="fas fa-phone"></i> +358 40 557 8629</p>
                    
                </div>
                
            </div>
            
            <div className="footerKuva"></div>
        </div>
    )
}
